<template>
  <HeaderObj />
</template>

<script>
import HeaderObj from './components/HeaderObj.vue'

export default {
  name: 'App',
  components: {
    HeaderObj
  }
}
</script>

<style>
body{
  background: #222;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222;
  margin-top: 60px;
}
</style>
