<template>
<header>
  <h1>{{ title }}</h1>
  <div class="center">
    <div class="mushroom">
      <img class="mushroom" src="../assets/MUSHROOM.png" alt="Mushroom">
    </div>
    <div class="punch">
    <img class="punch"  src="../assets/PUNCH.png" alt="punch">
    </div>
  </div>
</header>
</template>

<script>
export default {
  name: "HeaderObj",
  props: ["title"],
}
window.oncontextmenu = function () {
  console.log("Right Click Disabled");
  return false;
}
</script>

<style scoped>

* {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body{
  display: flex;
  background: #222;
  justify-content: center;
}
div.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
img {
  pointer-events: none;
}
img.punch {
  z-index: 1;
  position: absolute;
  top: 40px;
  right: -90px;
  animation: punch 10s infinite ease-in-out;
  width: 50%;
  height: auto
}
div.punch{
  transform: rotate(-10deg);
}

img.mushroom {
  z-index: 2;
  display: flex;
//margin: 5% auto 5% auto;
  animation: punch 20s infinite ease-in-out;
  width: 80%;
  height: auto
}
@keyframes punch {
  0%, 100%   {
    transform: scale(1);
  }
  98%, 100%   {
    transform: scale(1);
  }
  99%, 100%   {
    transform: scale(1.25);
  }
}

</style>